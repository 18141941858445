import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ReactComponent as SwitchOn } from 'assets/img/icon/btn_switch_on.svg';
import { ReactComponent as SwitchOff } from 'assets/img/icon/btn_switch_off.svg';

const SwitchField = ({ formikProps, id, label, onChange, disabled }) => {
  const { getFieldProps } = formikProps;
  const field = getFieldProps(id);

  return (
    <Stack spacing={1}>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          sx={{
            margin: 0,
            '& .MuiFormControlLabel-label': {
              color: '#424962',
              fontSize: '14px',
              lineHeight: 1.43
            }
          }}
          control={
            <Box
              onClick={onChange}
              role="button"
              aria-pressed={field.value}
              tabIndex={0}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                mr: 1
              }}
              disabled={disabled}
            >
              {field.value ? (
                <SwitchOn style={{ width: '28px', height: '16px' }} />
              ) : (
                <SwitchOff style={{ width: '28px', height: '16px' }} />
              )}
            </Box>
          }
          label={label}
          labelPlacement="end"
        />
      </Box>
    </Stack>
  );
};

export default SwitchField;
