export function toUnixTimestamp(input) {
  const convert = (date) => Math.floor(new Date(date).getTime() / 1000);

  if (Array.isArray(input)) {
    return input.map(date => convert(date));
  } else {
    return convert(input);
  }

  // Input: "Sun Oct 27 2024 00:00:00 GMT+0800 (台北標準時間)";
  // Input: ["Sun Oct 27 2024 00:00:00 GMT+0800 (台北標準時間)", "Fri Nov 01 2024 00:00:00 GMT+0800 (台北標準時間)"];

  // 也可以傳入  2024-10-25T00:00:00.000Z 格式

  // Output: 1727712000
  // Output: [1727712000, 1728067200]
}

export function unixToISO8601(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  return date.toISOString();

  // Input: 1729843200;

  // Output: "2024-10-25T00:00:00.000Z"
}

export function formatToISOString(date) {
  console.log(date);
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return offsetDate.toISOString();

  // Input: Fri Nov 15 2024 10:57:59 GMT+0800 (台北標準時間);

  // Output: "2024-10-25T00:00:00.000Z"
}

export function convertDateFormat(dateStr) {
  const date = new Date(dateStr);

  const weekday = date.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'Asia/Taipei' });
  const month = date.toLocaleDateString('en-US', { month: 'short', timeZone: 'Asia/Taipei' });
  const day = date.toLocaleDateString('en-US', { day: '2-digit', timeZone: 'Asia/Taipei' });
  const year = date.toLocaleDateString('en-US', { year: 'numeric', timeZone: 'Asia/Taipei' });

  const time = date.toLocaleTimeString('en-US', {
    hour12: false,
    timeZone: 'Asia/Taipei',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const timeZone = "GMT+0800";
  const timeZoneName = "台北標準時間";

  return `${weekday} ${month} ${day} ${year} ${time} ${timeZone} (${timeZoneName})`;

  // Input: "2024-10-25T00:00:00.000Z"

  // Output: Fri Nov 15 2024 10:57:59 GMT+0800 (台北標準時間);
}

export function toAmPmTime(date) {
  const d = new Date(date);

  let hours = d.getHours();
  let minutes = d.getMinutes();

  const ampm = hours >= 12 ? '下午' : '上午';

  hours = hours % 12;
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes} ${ampm}`;

  // Input: "2024-10-25T00:00:00.000Z";

  // Output: 08:00 上午
}