import React from 'react';

import dayjs from 'dayjs';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import { ReactComponent as InputTimeIcon } from 'assets/img/icon/icn_dropdown_time_default.svg';

const PickTimeField = ({ formikProps, id, label, value, onChange, disabled }) => {
  const { errors, touched } = formikProps;
  const hasError = Boolean(touched[id] && errors[id]);

  function handleFormat(currentValue) {
    const date = dayjs(currentValue);
    const period = date.hour() < 12 ? '上午' : '下午';
    return `yyyy年MM月dd日 ${period} hh:mm`;
  }

  return (
    <Stack spacing={1}>
      <InputLabel
        sx={{ color: '#737791', fontSize: '14px', fontWeight: 600, lineHeight: 1.43 }}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <FormControl
        fullWidth
        error={hasError}
      >
        <MobileDateTimePicker
          sx={{
            '& .MuiInputBase-root': { borderRadius: '10px', fontSize: '14px', lineHeight: 1.43 },
            '& .MuiInputBase-input': { color: '#737791', fontSize: '14px' },
            '& fieldset': {
              borderColor: hasError ? '#D3302F' : '#EEF0F2',
            }
          }}
          value={value}
          format={handleFormat(value)}
          onChange={onChange}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer', width: '16px', height: '16px' }}>
                    <InputTimeIcon />
                  </InputAdornment>
                )
              }
            },
            paper: {
              sx: { zIndex: 2000 }
            }
          }}
          disabled={disabled}
        />
        {touched[id] && errors[id] && (
          <FormHelperText>{errors[id]}</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
}

export default PickTimeField;