import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import LayoutOutlined from '@ant-design/icons/LayoutOutlined';
import PicCenterOutlined from '@ant-design/icons/PicCenterOutlined';
import OrderedListOutlined from '@ant-design/icons/OrderedListOutlined';

export const CALENDAR_VIEW_OPTIONS = [
  {
    label: '月',
    value: 'dayGridMonth',
    icon: AppstoreOutlined
  },
  {
    label: '週',
    value: 'timeGridWeek',
    icon: LayoutOutlined
  },
  {
    label: '日',
    value: 'timeGridDay',
    icon: PicCenterOutlined
  },
  {
    label: '議程',
    value: 'listWeek',
    icon: OrderedListOutlined
  }
];

export const CALENDAR_EVENT_COLORS = {
  ['outside']: ['#7ebbd6', '#e9f4fa'],
  ['hospital']: ['#55ccdd', '#e6fffb'],
  ['other']: ['#737791', '#efefef'],
  ['takeBloodPressure']: ['#ff947a', '#fff7e6'],
  ['takeMedicine']: ['#d3adf7', '#f9f0ff'],
}

export const TYPE_OPTIONS = [
  {
    value: 'outside',
    label: '外出活動',
  },
  {
    value: 'takeBloodPressure',
    label: '生理量測',
  },
  {
    value: 'takeMedicine',
    label: '用藥',
  },
  {
    value: 'hospital',
    label: '醫療',
  },
  {
    value: 'other',
    label: '其他',
  }
]

export const EDIT_TYPE_OPTIONS = [
  {
    value: 'outside',
    label: '外出活動',
  },
  {
    value: 'hospital',
    label: '醫療',
  },
  {
    value: 'other',
    label: '其他',
  }
]