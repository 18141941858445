import React from 'react';

import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const InputTextField = ({ formikProps, id, label, placeholder, icon, disabled }) => {
  const { errors, touched, getFieldProps } = formikProps;

  return (
    <Stack spacing={1}>
      <InputLabel
        sx={{ color: '#737791', fontSize: '14px', fontWeight: 600, lineHeight: 1.43 }}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <TextField
        sx={{
          '& .MuiInputBase-root': { borderRadius: '10px', fontSize: '14px', lineHeight: 1.43 },
          '& .MuiInputBase-input': { color: '#737791', fontSize: '14px' },
          '& fieldset': {
            borderColor: '#EEF0F2',
          }
        }}
        fullWidth
        id={id}
        placeholder={placeholder}
        {...getFieldProps(id)}
        error={Boolean(touched[id] && errors[id])}
        helperText={touched[id] && errors[id]}
        InputProps={{
          endAdornment: icon ? (
            <InputAdornment position="end" sx={{ width: '16px', height: '16px' }}>
              {icon}
            </InputAdornment>
          ) : null
        }}
        disabled={disabled}
      />
    </Stack>
  );
};

export default InputTextField;
